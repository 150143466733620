import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userToken: null,
        userInfo: null,
    },
    reducers: {
        loginUser: (state, action) => {
            state.userToken = action.payload;
        },
        logoutUser: (state) => {
            state.userToken = null;
            state.userInfo = null;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        clearUserInfo: (state, action) => {
            state.userInfo = null;
        },
    },
})

export const { loginUser, logoutUser, setUserInfo, clearUserInfo } = authSlice.actions
export default authSlice.reducer