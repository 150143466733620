import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Login from './components/auth/Login'; // Create Login component
import Navigation from './components/navigation/Navigation';
import Dashboard from './components/global/Dashboard';
import Home from './components/global/Home';
import NewReport from './components/worker/NewReport';
import NewReportAdmin from './components/admin/NewReportAdmin';
import Account from './components/user/Account';
import AccountAdmin from './components/user/AccountAdmin';
import Works from './components/admin/Works';
import Users from './components/admin/Users';


import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Modal, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { loginUser, logoutUser } from '../src/reducer/auth'
import { showModal } from '../src/reducer/global'
import { checkToken } from '../src/helpers/rest'
// import { Dashboard } from '@mui/icons-material';

import privateAreaBackgroundImg from './resources/images/bkg-private-area.jpg';

function App() {

  const userToken = useSelector((state) => state.auth.userToken);
  const loaderCtr = useSelector((state) => state.global.loading);
  const modalCtr = useSelector((state) => state.global.modal);
  const modalText = useSelector((state) => state.global.modalText);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const dispatch = useDispatch();

  const [appLoaded, setAppLoaded] = useState(false);

  function getToken() {
    const tokenString = localStorage.getItem('token');
    return tokenString;
  }

  useEffect(() => {
    (async () => {
      const savedUserToken = getToken();
      if (savedUserToken !== undefined && savedUserToken !== null) {
        const tokenCheckRes = await checkToken(savedUserToken);
        if (tokenCheckRes === true) {
          dispatch(loginUser(savedUserToken));
        } else {
          localStorage.removeItem('token');
          dispatch(logoutUser());
        }
      }
      setAppLoaded(true);
    })();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {loaderCtr &&
        <Backdrop
          // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          sx={{ color: '#fff', zIndex: 9999 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      {modalCtr &&
        <Dialog
          open={modalCtr}
          onClose={() => dispatch(showModal(false))}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {modalText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatch(showModal(false))} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      }
      {appLoaded && userToken === null &&
        <Login />
      }
      {appLoaded && userToken !== null &&
        <div className='routes-holder'
          // style={{
          //   display: 'flex',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          //   minHeight: '100vh', // Ensure the container takes up the full viewport height,
          //   marginBottom: 80,
          // }}
          >
          <Router>
            <Switch >
              <Route exact path="/Works">
                <Works />
              </Route>
              <Route exact path="/Users">
                <Users />
              </Route>
              <Route exact path="/NewReport">
                {userInfo !== null && userInfo.is_admin === true &&
                  <NewReportAdmin />
                }
                {userInfo !== null && userInfo.is_admin === false &&
                  <NewReport />
                }
              </Route>
              <Route exact path="/Home" >
                <Dashboard />
              </Route>
              <Route exact path="/Account" >
                {userInfo !== null && userInfo.is_admin === true &&
                  // <AccountAdmin />
                  <Account />
                }
                {userInfo !== null && userInfo.is_admin === false &&
                  <Account />
                }
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
            </Switch>
            <Navigation />
            <div
              style={
                {
                  zIndex: -1,
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundImage: `url(${privateAreaBackgroundImg})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}>
            </div>
          </Router>
        </div>
      }
    </LocalizationProvider >
  );
}

export default App;
