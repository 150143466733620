import React, { useState, useEffect } from 'react';
import { Button, TextField, Alert, AlertTitle, Typography } from '@mui/material';
import './Login.css';
import LogoImg from '../../resources/images/logo.jpg';
import backgroundImg from '../../resources/images/bkg-img.jpg';

import { useSelector, useDispatch } from 'react-redux'
import { loginUser, logoutUser } from '../../reducer/auth'
import { loginProcess, sendVerificationCode, accountPasswordRecovery } from '../../helpers/rest'

function Login() {
  const userToken = useSelector((state) => state.auth.userToken)
  const dispatch = useDispatch()

  const [email, setEmail] = useState(''); // 'goncalo.rg123@gmail.com'
  const [password, setPassword] = useState(''); // 'gR2023#!'
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  const [passwordRecoveryShow, setPasswordRecoveryShow] = useState(false);

  const [confirmCode, setConfirmCode] = useState('');
  const [recoveryPassword, setRecoveryPassword] = useState('');
  const [recoveryConfPassword, setRecoveryConfPassword] = useState('');

  function setToken(userToken) {
    localStorage.setItem('token', userToken);
  }

  const handleLogin = async () => {

    setSuccessMessage(null);

    const loginResult = await loginProcess(email, password);
    if (loginResult.status === true) {
      const userToken = loginResult.authToken;
      dispatch(loginUser(userToken));
      setToken(userToken);
    } else {
      setErrorMessage(loginResult.message)
    }

  };

  const passwordRecoveryOpen = async () => {

    if (email === '') {
      setErrorMessage("Tem de preencher o email");
      return;
    }

    const verificationCodeResult = await sendVerificationCode(email);
    if (verificationCodeResult.status === true) {
      setErrorMessage(null);
      setPasswordRecoveryShow(true);
    } else {
      setErrorMessage("Erro ao recuperar a password");
    }

  };

  const cancelRecovery = async () => {
    setPasswordRecoveryShow(false);
    setConfirmCode('')
    setRecoveryPassword('')
    setRecoveryConfPassword('')
    setErrorMessage(null);
  };

  const handleRecovery = async () => {

    if (confirmCode === '') {
      setErrorMessage("Tem de preencher o código de verificação");
      return;
    }
    if (recoveryPassword === '') {
      setErrorMessage("Tem de preencher a password");
      return;
    }
    if (recoveryConfPassword === '') {
      setErrorMessage("Tem de preencher a verificação da password");
      return;
    }

    if (recoveryPassword !== recoveryConfPassword) {
      setErrorMessage("A confirmação da password tem de ser igual à password");
      return;
    }

    if (recoveryPassword.length <= 4) {
      setErrorMessage("A password tem de ter mais de 4 caractéres");
      return;
    }
  
    // Check if the password has less than 20 characters
    if (recoveryPassword.length >= 20) {
      setErrorMessage("A password tem de ter menos de 20 caractéres"); 
      return;
    }
  
    // Check if the password contains at least 1 numeric character
    if (!/\d/.test(recoveryPassword)) {
      setErrorMessage("A password tem de ter pelo menos 1 caractér numérico");
      return;
    }

    const passRecoveryResult = await accountPasswordRecovery(email, confirmCode, recoveryPassword);
    if (passRecoveryResult.status !== true) {
      setErrorMessage(passRecoveryResult.message);
      return;
    }

    setSuccessMessage("Password recuperada com sucesso. Já pode efetuar o login com a nova password.");
    setErrorMessage(null);
    setPasswordRecoveryShow(false);
    setConfirmCode('')
    setRecoveryPassword('')
    setRecoveryConfPassword('')

  };


  useEffect(() => {

  }, [userToken]);


  return (
    <div className="login-container" style={
      {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover', // Adjust this property as needed
        backgroundRepeat: 'no-repeat', // Adjust this property as needed
      }
    }>
      {!passwordRecoveryShow &&
        <div className="login-box">
          <img src={LogoImg} alt="Planeta Salteado" style={{ maxWidth: 150, height: 'auto', marginBottom: 0 }} />
          <h1>Login</h1>

          {successMessage !== null &&
            <Alert severity="success" style={{ textAlign: 'left', marginBottom: 10 }}>
              <AlertTitle>Sucesso</AlertTitle>
              {successMessage}
            </Alert>
          }

          {errorMessage !== null &&
            <Alert severity="error" style={{ textAlign: 'left', marginBottom: 10 }}>
              <AlertTitle>Erro</AlertTitle>
              {errorMessage}
            </Alert>
          }

          {/* <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      /> */}
          <TextField className='TextFieldItem' label="E-mail" variant="filled" value={email} onChange={(e) => setEmail(e.target.value)} />
          <TextField className='TextFieldItem' type="password" label="Password" variant="filled" value={password} onChange={(e) => setPassword(e.target.value)} />
          {/* <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      /> */}

          <Typography style={{
            fontSize: 12,
            color: '#1976d2',
            cursor: 'pointer',
            paddingTop: 0,
            paddingBottom: 15,
            textAlign: 'right',
          }}
            onClick={passwordRecoveryOpen}
          >Recuperar password</Typography>

          <Button onClick={handleLogin} variant="outlined">Login</Button>

        </div>
      }
      {passwordRecoveryShow &&
        <div className="login-box">
          <img src={LogoImg} alt="Planeta Salteado" style={{ maxWidth: 150, height: 'auto', marginBottom: 0 }} />
          <h1>Recuperar password</h1>
          <Typography style={{ marginBottom: 10 }}>
            Foi enviado um código de verificação para o seu email (<b>{email}</b>).
            <br />
            Introduza esse código na caixa de texto em baixo e defina a nova password.
          </Typography>

          {errorMessage !== null &&
            <Alert severity="error" style={{ textAlign: 'left', marginBottom: 10 }}>
              <AlertTitle>Erro</AlertTitle>
              {errorMessage}
            </Alert>
          }
          <TextField className='TextFieldItem' label="Código de verificação" variant="filled" value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)} />
          <TextField className='TextFieldItem' type="password" label="Password" variant="filled" value={recoveryPassword} onChange={(e) => setRecoveryPassword(e.target.value)} />
          <TextField className='TextFieldItem' type="password" label="Confirmação da Password" variant="filled" value={recoveryConfPassword} onChange={(e) => setRecoveryConfPassword(e.target.value)} />

          <Button onClick={handleRecovery} variant="outlined">Recuperar</Button>
          <Button onClick={cancelRecovery} variant="outlined" color="error">Cancelar</Button>
        </div>
      }

    </div>
  );
}

export default Login;
