

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import store from '../reducer/store';

export async function checkToken(JWTToken) {

    return new Promise((resolve) => {
        try {

            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/auth/token-verify',
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JWTToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            resolve(false);
        }

    });
}

export async function loginProcess(email, password) {

    return new Promise((resolve) => {

        try {

            const requestBody = {
                email: email,
                password: password
            };
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/auth',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios(config)
                .then(function (response) {
                    let ret = {
                        status: true,
                        message: 'OK',
                        authToken: response.data.authToken
                    }
                    resolve(ret);
                })
                .catch(function (error) {
                    let ret = {
                        status: false,
                        message: error?.response?.data?.message
                    }
                    resolve(ret);
                });

        } catch (error) {
            let ret = {
                status: false
            }
            resolve(ret);
        }

    });

}

export async function getDashboardData(dashboardName) {

    return new Promise((resolve) => {
        try {

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/v1/dashboard/' + dashboardName,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            resolve(false);
        }

    });
}

export async function getUserInfo() {

    return new Promise((resolve) => {
        try {

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/v1/user',
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            resolve(false);
        }

    });
}

export async function getWork(workID) {

    return new Promise((resolve) => {
        try {

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/v1/work/' + Number(workID),
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data.work[0]);
                })
                .catch(function (error) {
                    console.log(error);
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function getWorks(filter = '/active') {

    return new Promise((resolve) => {
        try {

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/v1/work' + filter,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data.works);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            resolve(false);
        }

    });
}

export async function getUsers(filter = '') {

    return new Promise((resolve) => {
        try {

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/v1/user/all' + filter,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data.users);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            resolve(false);
        }

    });
}

export async function getUser(userID) {

    return new Promise((resolve) => {
        try {

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/v1/user/' + Number(userID),
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            resolve(false);
        }

    });
}

export async function createUser(name, email) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
                name: name,
                email: email
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/user/',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    if (response.data.status === 'OK') {
                        resolve(true);
                    } else {
                        resolve(response.data.message);
                    }
                })
                .catch(function (error) {
                    resolve("Erro ao criar utilizador");
                });

        } catch (error) {
            console.log(error);
            resolve("Erro ao criar utilizador");
        }

    });
}

export async function updateUser(userID, name, email) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
                name: name,
                email: email
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'put',
                url: process.env.REACT_APP_API_URL + '/v1/user/' + Number(userID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    if (response.data.status === 'OK') {
                        resolve(true);
                    } else {
                        resolve(response.data.message);
                    }
                })
                .catch(function (error) {
                    resolve("Erro ao editar utilizador");
                });

        } catch (error) {
            console.log(error);
            resolve("Erro ao editar utilizador");
        }

    });
}

export async function activateUser(userID) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'patch',
                url: process.env.REACT_APP_API_URL + '/v1/user/activate/' + Number(userID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function deactivateUser(userID) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'patch',
                url: process.env.REACT_APP_API_URL + '/v1/user/deactivate/' + Number(userID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function blockUser(userID) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'patch',
                url: process.env.REACT_APP_API_URL + '/v1/user/block/' + Number(userID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function unblockUser(userID) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'patch',
                url: process.env.REACT_APP_API_URL + '/v1/user/unblock/' + Number(userID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function createWorkReport(workID, date, hours, materials = '', notes = '') {

    return new Promise((resolve) => {
        try {

            const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero if needed
            const year = date.getFullYear();
            const formattedDate = `${year}-${month}-${day}`;

            const requestBody = {
                date: formattedDate,
                hours: hours,
                materials: materials,
                notes: notes,
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/workreport/work/' + Number(workID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });



        } catch (error) {
            resolve(false);
        }

    });
}

export async function createWorkReportAdmin(workID, userID, date, hours, materials = '', notes = '') {

    return new Promise((resolve) => {
        try {

            const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero if needed
            const year = date.getFullYear();
            const formattedDate = `${year}-${month}-${day}`;

            const requestBody = {
                user: userID,
                date: formattedDate,
                hours: hours,
                materials: materials,
                notes: notes,
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/workreport/work/' + Number(workID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });



        } catch (error) {
            resolve(false);
        }

    });
}

export function formatDate(_inputDate) {
    const date = new Date(_inputDate);
    const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero if needed
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export async function createWork(dateIni, dateEnd, name, hours, notes = '') {

    return new Promise((resolve) => {
        try {

            const requestBody = {
                dateIni: formatDate(dateIni),
                dateEnd: formatDate(dateEnd),
                name: name,
                estimatedHours: hours,
                notes: notes
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/work/',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function updateWork(workID, dateIni, dateEnd, name, hours, notes = '') {

    return new Promise((resolve) => {
        try {

            const requestBody = {
                dateIni: formatDate(dateIni),
                dateEnd: formatDate(dateEnd),
                name: name,
                estimatedHours: hours,
                notes: notes
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'put',
                url: process.env.REACT_APP_API_URL + '/v1/work/' + Number(workID),
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function closeWork(workID) {

    return new Promise((resolve) => {
        try {

            const requestBody = {};
            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'patch',
                url: process.env.REACT_APP_API_URL + '/v1/work/' + Number(workID) + "/close",
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });

        } catch (error) {
            console.log(error);
            resolve(false);
        }

    });
}

export async function saveUserData(infoData) {

    return new Promise((resolve) => {
        try {

            const requestBody = {
                name: infoData.name
            };

            const state = store.getState();
            const userToken = state.auth.userToken;
            const config = {
                method: 'put',
                url: process.env.REACT_APP_API_URL + '/v1/user',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };
            axios(config)
                .then(function (response) {
                    resolve(true);
                })
                .catch(function (error) {
                    resolve(false);
                });


        } catch (error) {
            resolve(false);
        }

    });
}

export async function updateUserPassword(newPassword) {

    return new Promise((resolve) => {

        try {


            const state = store.getState();
            const userToken = state.auth.userToken;

            const requestBody = {
                password: newPassword
            };
            const config = {
                method: 'put',
                url: process.env.REACT_APP_API_URL + '/v1/auth/password',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            };

            axios(config)
                .then(function (response) {
                    let ret = {
                        status: true,
                        message: 'OK',
                        authToken: response.data.authToken
                    }
                    resolve(ret);
                })
                .catch(function (error) {
                    let ret = {
                        status: false,
                        message: error?.response?.data?.message
                    }
                    resolve(ret);
                });

        } catch (error) {
            let ret = {
                status: false
            }
            resolve(ret);
        }

    });

}


export async function sendVerificationCode(email) {

    return new Promise((resolve) => {

        try {

            const requestBody = {
                email: email
            };
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/auth/password-recovery',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios(config)
                .then(function (response) {
                    let ret = {
                        status: true,
                        message: 'OK'
                    }
                    resolve(ret);
                })
                .catch(function (error) {
                    let ret = {
                        status: false,
                        message: error?.response?.data?.message
                    }
                    resolve(ret);
                });

        } catch (error) {
            let ret = {
                status: false
            }
            resolve(ret);
        }

    });

}


export async function accountPasswordRecovery(email, confirmCode, recoveryPassword) {

    return new Promise((resolve) => {

        try {

            const requestBody = {
                email: email,
                recoveryKey: confirmCode,
                password: recoveryPassword
            };
            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/v1/auth/password-recovery-confirm',
                data: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios(config)
                .then(function (response) {
                    let ret = {
                        status: true,
                        message: 'OK'
                    }
                    resolve(ret);
                })
                .catch(function (error) {
                    let ret = {
                        status: false,
                        message: error?.response?.data?.message
                    }
                    resolve(ret);
                });

        } catch (error) {
            let concatenatedMsg = "";
            if (error?.response?.data?.errors !== undefined) {
                concatenatedMsg = error?.response?.data?.errors.map(error => error.msg);
            }
            let ret = { 
                status: false,
                message: concatenatedMsg
            }
            resolve(ret);
        }

    });

}

