import React, { useState, useEffect } from 'react';
import { Button, Container, Divider, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { setLoading, showModal, setModalText } from '../../reducer/global'
import { getDashboardData } from '../../helpers/rest'
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid';

function DashboardComponent(props) {

  const dispatch = useDispatch()
  const [dashLoading, setDashLoading] = useState(true);
  const [datatableData, setDatatableData] = useState([]);

  useEffect(() => {
    (async () => {
      const avaliableDataRes = await getDashboardData(props.dashboardName);
      if (avaliableDataRes !== false) {

        for (let index = 0; index < avaliableDataRes.length; index++) {
          const record = avaliableDataRes[index];
          const itemToAdd = {
            id: index
          }
          Object.keys(record).forEach(function (key) {
            itemToAdd[key] = record[key];
          });
          setDatatableData(datatableData => [...datatableData, itemToAdd]);
        }
      }
      setDashLoading(false);
    })()
  }, []);

  return (
    <Container maxWidth="lg"
      style={{
        backgroundColor: '#EBE8E8',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: '#D2D2D2',
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        width: (props.fullWidth !== undefined ? 1210 : 600),
        maxWidth: '100%',
        height: 'auto'
      }}>

      {dashLoading &&
        <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10
        }}>
          <CircularProgress />
        </div>
      }

      <Typography variant="h4" style={{ marginTop: 10, textAlign: 'center' }}>
        {props.title}
      </Typography>

      <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      <div style={{ height: 'auto', width: '100%', whiteSpace: "pre-line" }}>
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
          sx={{
            '--DataGrid-overlayHeight': '100px',
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            }
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          style={{ marginBottom: 20 }}
          columns={props.columnHeaders}
          rows={datatableData}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          getRowHeight={() => 'auto'}
          disableColumnResize
        />
      </div>

    </Container>
  );
}

export default DashboardComponent;
