import React, { useState, useEffect } from 'react';
import { Button, Container, Divider, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { setUserInfo } from '../../reducer/auth'
import { setLoading, showModal, setModalText } from '../../reducer/global'
import { getUserInfo, saveUserData, updateUserPassword } from '../../helpers/rest'

function Account() {

  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const [infoData, setInfoData] = useState({
    name: userInfo.name,
  });
  const [passChangeOpen, setPassChangeOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  useEffect(() => {
    // dispatch(setLoading(true));
    (async () => {

      // dispatch(setLoading(false));
    })()
  }, []);

  const submitForm = async () => {

    if (infoData.name === '') {
      setErrorMessage("O campo nome não pode estar vazio");
      return;
    }
    setErrorMessage(null);

    dispatch(setLoading(true));
    const updRes = await saveUserData(infoData);
    dispatch(setLoading(false));
    if (updRes) {
      dispatch(setModalText("Dados gravados com sucesso"));
      dispatch(showModal(true));
      const userData = await getUserInfo();
      if (userData !== false) {
        dispatch(setUserInfo(userData));
      }
    } else {
      dispatch(setModalText("Erro ao gravar dados"));
      dispatch(showModal(true));
    }

  };

  const passwordChangeRequest = async () => {
    setPassChangeOpen(true);
  };
  const submitPassChange = async () => {

    if (password === '' || confPassword === '') {
      setPasswordErrorMessage("Tem de preencher a password e a confirmação da password");
      return;
    }

    if (password !== confPassword) {
      setPasswordErrorMessage("A confirmação da password é diferente da password");
      return;
    }


    const updPass = await updateUserPassword(password);
    if (updPass.status === true) {
      setPassChangeOpen(false);
      setPasswordErrorMessage(null);
      dispatch(setModalText("Password alterada com sucesso"));
      dispatch(showModal(true));
    } else {
      setPasswordErrorMessage(updPass.message)
    }
  };


  return (
    <Container maxWidth="sm"
      style={{
        backgroundColor: '#EBE8E8',
        marginTop: 20,
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#D2D2D2',
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center'
      }}>

      <Typography variant="h4" style={{ marginTop: 10, textAlign: 'center' }}>
        Dados da conta
      </Typography>

      <Divider style={{ marginTop: 10, marginBottom: 20 }} />

      {errorMessage !== null &&
        <Alert severity="error" style={{ textAlign: 'left', marginBottom: 20 }}>
          <AlertTitle>Erro</AlertTitle>
          {errorMessage}
        </Alert>
      }

      <TextField
        label="Nome"
        style={{ marginTop: -5 }}
        value={infoData.name}
        onChange={(e) => {
          setInfoData({
            ...infoData,
            name: e.target.value,
          })
        }}
      />

      <Box style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>
        <Button
          style={{
            marginTop: 10,
            marginBottom: 20
          }}
          onClick={passwordChangeRequest}
          variant="contained">Alterar password</Button>

        <Button
          style={{
            marginTop: 10,
            marginBottom: 20
          }}
          onClick={submitForm}
          variant="outlined">Gravar</Button>
      </Box>

      <Dialog open={passChangeOpen} onClose={() => setPassChangeOpen(false)}>
        <DialogTitle>Alterar Password</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          {passwordErrorMessage !== null &&
            <Alert severity="error" style={{ textAlign: 'left', marginBottom: 20 }}>
              <AlertTitle>Erro</AlertTitle>
              {passwordErrorMessage}
            </Alert>
          }
          <TextField className='TextFieldItem' type="password" label="Password" variant="filled" value={password} onChange={(e) => setPassword(e.target.value)} />
          <TextField className='TextFieldItem' type="password" label="Confirmação Password" variant="filled" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPassChangeOpen(false)}>Fechar</Button>
          <Button onClick={() => submitPassChange()}>Alterar</Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
}

export default Account;
