import React, { useState, useEffect } from 'react';
import { Button, Container, Divider, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { setLoading, showModal, setModalText } from '../../reducer/global'
import { getUsers, getUser, createUser, updateUser, deactivateUser, activateUser, blockUser, unblockUser } from '../../helpers/rest'
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid';
import { Edit, Block, PersonOff } from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Users() {

  const dispatch = useDispatch()
  const tableHeaders = [
    { field: 'name', headerName: 'Nome', flex: 1, minWidth: 180 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 180 },
    { field: 'is_admin', headerName: 'Administrador', flex: 1, minWidth: 180 },
    { field: 'is_active', headerName: 'Activo', flex: 1, minWidth: 180 },
    { field: 'is_blocked', headerName: 'Bloqueado', flex: 1, minWidth: 180 },
    { field: 'failed_logins', headerName: 'Logins falhados', flex: 1, minWidth: 180 },
    {
      field: 'button',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => {
        if (params.row.is_admin.toUpperCase() == "NÃO") {
          return (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Button style={{ width: 30, margin: 5 }} variant="outlined">
                <Edit
                  onClick={() => editUserHandler(params.row)}
                  style={{ cursor: 'pointer' }}
                />
              </Button>
              <Button style={{ width: 30, margin: 5 }} variant="outlined" color="warning" >
                <PersonOff
                  onClick={() => {
                    if (params.row.is_active.toUpperCase() == 'SIM') {
                      processDeactivateUser(params.row)
                    } else {
                      processActivateUser(params.row)
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                  color="warning"
                />
              </Button>
              <Button style={{ width: 30, margin: 5 }} variant="outlined" color="error">
                <Block
                  onClick={() => {
                    if (params.row.is_blocked.toUpperCase() == 'SIM') {
                      processUnblockUser(params.row)
                    } else {
                      processBlockUser(params.row)
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                  color="error"
                />
              </Button>
              {/* <Button style={{ width: 30, margin: 5 }} variant="outlined" color='success'>
                <AssignmentTurnedInIcon
                  onClick={() => markAsCompleted(params.row)}
                  style={{ cursor: 'pointer' }}
                  color='success'
                />
              </Button> */}
            </div>
          )
        } else {
          return (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Button style={{ width: 30, margin: 5 }} variant="outlined">
                <Edit
                  onClick={() => editUserHandler(params.row)}
                  style={{ cursor: 'pointer' }}
                />
              </Button>
            </div>
          )
        }
      },
    },
  ];

  const [columnTitles, setColumnTitles] = useState(tableHeaders);
  const [datatableData, setDatatableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userData, setUserData] = useState({
    name: '',
    email: ''
  });
  const [dashLoading, setDashLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [workID, setWorkID] = useState(0);

  const handleClickOpen = () => {
    setWorkID(0);
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setUserData({
      ...userData,
      name: '',
      email: ''
    })
    setWorkID(0);
    setEditMode(false);
    setOpen(false);
  };

  const processDeactivateUser = async (row) => {
    dispatch(setLoading(true));
    const ret = await deactivateUser(row.id);
    dispatch(setLoading(false));
    if (ret === true) {
      dispatch(setModalText("Utilizador desativado com sucesso"));
      dispatch(showModal(true));
      loadUsers();
    } else {
      dispatch(setModalText("Erro ao desativar utilizador"));
      dispatch(showModal(true));
    }
  }

  const processActivateUser = async (row) => {
    dispatch(setLoading(true));
    const ret = await activateUser(row.id);
    dispatch(setLoading(false));
    if (ret === true) {
      dispatch(setModalText("Utilizador activado com sucesso"));
      dispatch(showModal(true));
      loadUsers();
    } else {
      dispatch(setModalText("Erro ao activado utilizador"));
      dispatch(showModal(true));
    }
  }

  const processBlockUser = async (row) => {
    dispatch(setLoading(true));
    const ret = await blockUser(row.id);
    dispatch(setLoading(false));
    if (ret === true) {
      dispatch(setModalText("Utilizador bloqueado com sucesso"));
      dispatch(showModal(true));
      loadUsers();
    } else {
      dispatch(setModalText("Erro ao bloquear utilizador"));
      dispatch(showModal(true));
    }
  }

  const processUnblockUser = async (row) => {
    dispatch(setLoading(true));
    const ret = await unblockUser(row.id);
    dispatch(setLoading(false));
    if (ret === true) {
      dispatch(setModalText("Utilizador desbloqueado com sucesso"));
      dispatch(showModal(true));
      loadUsers();
    } else {
      dispatch(setModalText("Erro ao desbloquear utilizador"));
      dispatch(showModal(true));
    }
  }

  const editUserHandler = async (row) => {
    dispatch(setLoading(true));
    const retUserData = await getUser(row.id);
    dispatch(setLoading(false));
    if (retUserData !== false) {
      setWorkID(row.id);
      setUserData({
        ...userData,
        name: retUserData.name,
        email: retUserData.email
      });
      setEditMode(true);
      setOpen(true);
    } else {
      dispatch(setModalText("Erro ao obter informação da obra"));
      dispatch(showModal(true));
    }

  }

  const handleUserCreate = async () => {

    if (userData.name === '') {
      setErrorMessage("Tem de preencher o nome do utilizador");
      return;
    }
    if (userData.email === '') {
      setErrorMessage("Tem de preencher o email do utilizador");
      return;
    }

    setErrorMessage(null);
    dispatch(setLoading(true));
    const insRes = await createUser(userData.name, userData.email);
    dispatch(setLoading(false));
    if (insRes === true) {
      loadUsers();
      dispatch(setModalText("Utilizador criado com sucesso"));
      dispatch(showModal(true));
      setUserData({
        ...userData,
        name: '',
        email: ''
      })
      setOpen(false);
    } else {
      dispatch(setModalText(insRes));
      dispatch(showModal(true));
    }

  };

  const handleWorkEdit = async () => {

    if (userData.name === '') {
      setErrorMessage("Tem de preencher o nome do utilizador");
      return;
    }
    if (userData.email === '') {
      setErrorMessage("Tem de preencher o email do utilizador");
      return;
    }

    setErrorMessage(null);
    dispatch(setLoading(true));
    const insRes = await updateUser(workID, userData.name, userData.email);
    dispatch(setLoading(false));
    if (insRes === true) {
      loadUsers();
      dispatch(setModalText("Utilizador editado com sucesso"));
      dispatch(showModal(true));
      setUserData({
        ...userData,
        name: '',
        email: ''
      })
      setOpen(false);
      setEditMode(false);
    } else {
      dispatch(setModalText(insRes));
      dispatch(showModal(true));
    }

  };

  const loadUsers = async () => {
    setDatatableData(datatableData => []);
    dispatch(setLoading(true));
    const avaliableUsersRes = await getUsers('');
    if (avaliableUsersRes !== false) {

      for (let index = 0; index < avaliableUsersRes.length; index++) {
        const record = avaliableUsersRes[index];
        const itemToAdd = {
          id: record['id'],
          name: record['name'],
          email: record['email'],
          is_admin: (record['is_admin'] ? 'Sim' : 'Não'),
          is_active: (record['is_active'] ? 'Sim' : 'Não'),
          is_blocked: (record['is_blocked'] ? 'Sim' : 'Não'),
          failed_logins: record['failed_logins'],
        }
        setDatatableData(datatableData => [...datatableData, itemToAdd]);
      }
    }
    dispatch(setLoading(false));
    setDashLoading(false);
  }

  useEffect(() => {
    (async () => {
      loadUsers();
    })()
  }, []);

  return (
    <Container maxWidth="lg"
      style={{
        backgroundColor: '#EBE8E8',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: '#D2D2D2',
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        width: 1210,
        maxWidth: '100%',
        height: 'auto'
      }}>

      {dashLoading &&
        <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10
        }}>
          <CircularProgress />
        </div>
      }

      <Typography variant="h4" style={{ marginTop: 10, textAlign: 'center' }}>
        Gestão de utilizadores
      </Typography>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Button style={{ width: 200, marginBottom: 10 }} onClick={handleClickOpen} variant="outlined">Criar novo</Button>

      <div style={{ height: 'auto', width: '100%', whiteSpace: "pre-line" }}>
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
          sx={{
            '--DataGrid-overlayHeight': '100px',
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            }
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          style={{ marginBottom: 20 }}
          columns={columnTitles}
          rows={datatableData}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick={true}
          // isRowSelectable={false}
          rowSelection={false}
        />
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
        {!editMode &&
          <DialogTitle>Novo utilizador</DialogTitle>
        }
        {editMode &&
          <DialogTitle>Editar utilizador</DialogTitle>
        }
        <DialogContent>
          {errorMessage !== null &&
            <Alert severity="error" style={{ textAlign: 'left', marginBottom: 20 }}>
              <AlertTitle>Erro</AlertTitle>
              {errorMessage}
            </Alert>
          }
          <FormControl fullWidth style={{ marginBottom: 10 }}>

            <div style={{
              paddingTop: 10
            }}></div>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nome do utilizador"
              type="text"
              fullWidth
              variant="standard"
              style={{
                paddingTop: 10
              }}
              value={userData.name}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  name: e.target.value,
                })
              }}
              required
            />

            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              style={{
                paddingTop: 10
              }}
              value={userData.email}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  email: e.target.value,
                })
              }}
              required
            />

          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          {!editMode &&
            <Button onClick={handleUserCreate}>Criar</Button>
          }
          {editMode &&
            <Button onClick={handleWorkEdit}>Gravar</Button>
          }
        </DialogActions>
      </Dialog>

    </Container>
  );
}

export default Users;
