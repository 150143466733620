import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import DashboardComponent from '../dashboard/DashboardComponent';


function Dashboard() {

  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.auth.userInfo);

  const hoursByWorkAdminHeaders = [
    { field: 'name', headerName: 'Obra', flex: 1, minWidth: 180 },
    {
      field: 'hours', headerName: 'Horas', flex: 1, minWidth: 180,
      renderCell: (params) => {
        const hours = params.row.hours;
        const estimated_hours = params.row.estimated_hours;
        const shouldColorRed = estimated_hours < hours;
        return (
          <div
            style={{
              color: shouldColorRed ? 'red' : 'green',
              fontWeight: shouldColorRed ? 'bold' : 'normal',
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'estimated_hours', headerName: 'Horas estimadas', flex: 1, minWidth: 180
    },
    {
      field: 'is_closed', headerName: 'Obra concluida', flex: 1, minWidth: 180,
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <div>
              Sim
            </div>
          );
        } else {
          return (
            <div>
              Não
            </div>
          );
        }

      },
    }
  ];
  const hoursReportedHeadersAdmin = [
    { field: 'username', headerName: 'Utilizador', flex: 1, minWidth: 180 },
    { field: 'name', headerName: 'Obra', flex: 1, minWidth: 180 },
    { field: 'date', headerName: 'Data', flex: 1, minWidth: 180 },
    { field: 'materials', headerName: 'Materiais', flex: 1, minWidth: 180 },
    { field: 'notes', headerName: 'Notas', flex: 1, minWidth: 180 },
    { field: 'hours', headerName: 'Horas', flex: 1, minWidth: 180 },
  ];

  const hoursByMonthAdminHeaders = [
    { field: 'monthYear', headerName: 'Ano/Mês', flex: 1, minWidth: 180 },
    { field: 'name', headerName: 'Utilizador', flex: 1, minWidth: 180 },
    { field: 'hours', headerName: 'Horas', flex: 1, minWidth: 180 },
  ];

  //-----------------------------

  const hoursByWorkHeaders = [
    { field: 'name', headerName: 'Obra', flex: 1, minWidth: 180 }, { field: 'hours', headerName: 'Horas', flex: 1, minWidth: 180 },
  ];
  const hoursByMonthHeaders = [
    { field: 'monthYear', headerName: 'Ano/Mês', flex: 1, minWidth: 180 }, { field: 'hours', headerName: 'Horas', flex: 1, minWidth: 180 },
  ];
  const hoursReportedHeaders = [
    { field: 'name', headerName: 'Obra', flex: 1, minWidth: 180 },
    { field: 'date', headerName: 'Data', flex: 1, minWidth: 180 },
    { field: 'materials', headerName: 'Materiais', flex: 1, minWidth: 180 },
    { field: 'notes', headerName: 'Notas', flex: 1, minWidth: 180 },
    { field: 'hours', headerName: 'Horas', flex: 1, minWidth: 180 },
  ];

  return (
    <div>
      {userInfo !== null && userInfo.is_admin &&
        <div style={{
          maxWidth: '100%',
          height: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <DashboardComponent fullWidth={true} title="Horas por obra" dashboardName="hoursByWorkAdmin" columnHeaders={hoursByWorkAdminHeaders} />
          <DashboardComponent fullWidth={true} title="Horas por mês" dashboardName="hoursByMonthAdmin" columnHeaders={hoursByMonthAdminHeaders} />
          <DashboardComponent fullWidth={true} title="Horas reportadas" dashboardName="hoursReportedAdmin" columnHeaders={hoursReportedHeadersAdmin} />
        </div>
      }
      {userInfo !== null && !userInfo.is_admin &&
        <div style={{
          maxWidth: '100%',
          height: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <DashboardComponent fullWidth={true} title="Horas por obra" dashboardName="hoursByWork" columnHeaders={hoursByWorkHeaders} />
          <DashboardComponent fullWidth={true} title="Horas por mês" dashboardName="hoursByMonth" columnHeaders={hoursByMonthHeaders} />
          <DashboardComponent fullWidth={true} title="Horas reportadas" dashboardName="hoursReported" columnHeaders={hoursReportedHeaders} />
        </div>
      }
    </div>
  );
}

export default Dashboard;
