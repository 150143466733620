import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button, styled, BottomNavigation, BottomNavigationAction, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
// Link
import './Navigation.css';
import backgroundImg from '../../resources/images/nav-bkg.jpg'; // Adjust the path to your image file
import { useSelector, useDispatch } from 'react-redux'
import { Home, Person, AddTask, Logout, Apartment } from '@mui/icons-material';
import { getUserInfo } from '../../helpers/rest'
import { setUserInfo, logoutUser } from '../../reducer/auth'
import { setLoading } from '../../reducer/global'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

function Navigation() {

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [value, setValue] = useState(0);
  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(setLoading(true));
    (async () => {

      const currentUrl = location.pathname; // or location.pathname + location.search for full URL
      setValue(currentUrl);

      const userData = await getUserInfo();
      if (userData !== false) {
        dispatch(setUserInfo(userData));
      }
      dispatch(setLoading(false));

    })()
  }, []);

  const logoutProcessConfirm = async () => {
    setLogoutConfirmOpen(true)
  }

  const logoutProcess = async () => {
    localStorage.removeItem('token');
    dispatch(logoutUser());
    history.push('/');
  }

  return (

    <div className="center-container">
      {userInfo !== null &&
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className='navigation-menu-holder'
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            maxWidth: '100%',
            left: 0,
            right: 0,
            backgroundColor: '#D2D2D2',
            borderTopColor: '#969696',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            overflowX: 'auto'
          }}
        >
          <BottomNavigationAction
            label="Inicio"
            icon={<Home
              sx={{ fontSize: 30 }}
            />}
            component={Link}
            to="/"
            value={"/"}
          />
          {/* {!userInfo.is_admin && */}
          {1 === 1 &&
            <BottomNavigationAction
              label="Reportar"
              icon={<AddTask
                sx={{ fontSize: 30 }}
              />}
              component={Link}
              to="/NewReport"
              value={"/NewReport"}
            />
          }
          {/* {!userInfo.is_admin && */}
          {1 === 1 &&
            <BottomNavigationAction
              label="Conta"
              icon={<Person
                sx={{ fontSize: 30 }}
              />}
              component={Link}
              to="/Account"
              value={"/Account"}
            />
          }
          {userInfo.is_admin &&
            <BottomNavigationAction
              label="Obras"
              icon={<Apartment
                sx={{ fontSize: 30 }}
              />}
              component={Link}
              to="/Works"
              value={"/Works"}
            />
          }
          {userInfo.is_admin &&
            <BottomNavigationAction
              label="Utilizadores"
              icon={<ManageAccountsIcon
                sx={{ fontSize: 30 }}
              />}
              component={Link}
              to="/Users"
              value={"/Users"}
            />
          }
          <BottomNavigationAction
            label="Sair"
            icon={<Logout
              sx={{ fontSize: 30 }}
            />}
            onClick={() => logoutProcessConfirm()}
          />
        </BottomNavigation>
      }
      <Dialog
        open={logoutConfirmOpen}
        onClose={() => setLogoutConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Terminar sessão"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem a certeza que pretende encerrar a sessão ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutConfirmOpen(false)}>Cancelar</Button>
          <Button onClick={() => logoutProcess()} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Navigation;
