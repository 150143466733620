import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        loading: false,
        modal: false,
        modalText: '',
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        showModal: (state, action) => {
            state.modal = action.payload;
        },
        setModalText: (state, action) => {
            state.modalText = action.payload;
        }
    },
})

export const { setLoading, showModal, setModalText } = globalSlice.actions
export default globalSlice.reducer