import React, { useState, useEffect } from 'react';
import { Button, Container, Divider, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { setLoading, showModal, setModalText } from '../../reducer/global'
import { getWorks, createWork, getWork, updateWork, closeWork } from '../../helpers/rest'
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid';
import { Edit } from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function Works() {

  const dispatch = useDispatch()
  const tableHeaders = [
    { field: 'name', headerName: 'Obra', flex: 1, minWidth: 180 },
    {
      field: 'init_date', headerName: 'Data de início', flex: 1,
      minWidth: 180,
      valueFormatter: (params) => {
        // Assuming 'dateOfBirth' is a Date object
        const date = params.value;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
      }
    },
    {
      field: 'end_date', headerName: 'Data de fim', flex: 1,
      minWidth: 180,
      valueFormatter: (params) => {
        // Assuming 'dateOfBirth' is a Date object
        const date = params.value;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
      }
    },
    { field: 'estimated_hours', headerName: 'Horas estimadas', flex: 1, minWidth: 180 },
    { field: 'is_closed', headerName: 'Concluida', flex: 1, minWidth: 180 },
    {
      field: 'button',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => {
        if ( params.row.is_closed.toUpperCase() == "NÃO") {
          return (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Button style={{ width: 30, margin: 5 }} variant="outlined">
                <Edit
                  onClick={() => editWorkHandler(params.row)}
                  style={{ cursor: 'pointer' }}
                />
              </Button>
              <Button style={{ width: 30, margin: 5 }} variant="outlined" color='success'>
                <AssignmentTurnedInIcon
                  onClick={() => markAsCompleted(params.row)}
                  style={{ cursor: 'pointer' }}
                  color='success'
                />
              </Button>
            </div>
          )
        }
      },
    },
  ];

  const [columnTitles, setColumnTitles] = useState(tableHeaders);
  const [datatableData, setDatatableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [workData, setWorkData] = useState({
    dateIni: '',
    dateEnd: '',
    name: '',
    hours: '',
    notes: ''
  });
  const [dashLoading, setDashLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [workID, setWorkID] = useState(0);

  const handleClickOpen = () => {
    setWorkID(0);
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setWorkData({
      ...workData,
      dateIni: '',
      dateEnd: '',
      name: '',
      hours: '',
      notes: ''
    })
    setWorkID(0);
    setEditMode(false);
    setOpen(false);
  };

  const markAsCompleted = async (row) => {
    setWorkID(row.id);
    setCloseOpen(true);
  }
  const handleWorkClose = async () => {
    setCloseOpen(false);
    dispatch(setLoading(true));
    const closeRes = await closeWork(workID);
    dispatch(setLoading(false));

    if (closeRes) {
      dispatch(setModalText("Obra encerrada com sucesso"));
      dispatch(showModal(true));
      loadWorks();
    } else {
      dispatch(setModalText("Erro ao fechar a obra"));
      dispatch(showModal(true));
    }
  }

  const editWorkHandler = async (row) => {
    dispatch(setLoading(true));
    const retWorkData = await getWork(row.id);
    dispatch(setLoading(false));
    if (retWorkData !== false) {
      setWorkID(row.id);
      setWorkData({
        ...workData,
        dateIni: retWorkData.init_date,
        dateEnd: retWorkData.end_date,
        name: retWorkData.name,
        hours: retWorkData.estimated_hours,
        notes: retWorkData.notes
      });
      setEditMode(true);
      setOpen(true);
    } else {
      dispatch(setModalText("Erro ao obter informação da obra"));
      dispatch(showModal(true));
    }

  }

  const handleWorkCreate = async () => {

    if (workData.dateIni === '') {
      setErrorMessage("Tem de selecionar a data de início");
      return;
    }
    if (workData.dateEnd === '') {
      setErrorMessage("Tem de selecionar a data de Fim");
      return;
    }
    if (workData.name === '') {
      setErrorMessage("Tem de preencher o nome da obra");
      return;
    }
    if (workData.hours === '' || Number(workData.hours) <= 0) {
      setErrorMessage("Tem de introduzir as horas estimadas");
      return;
    }

    setErrorMessage(null);
    dispatch(setLoading(true));
    const insRes = await createWork(workData.dateIni, workData.dateEnd, workData.name, workData.hours, workData.notes);
    dispatch(setLoading(false));
    if (insRes) {
      loadWorks();
      dispatch(setModalText("Registo introduzido com sucesso"));
      dispatch(showModal(true));
      setWorkData({
        ...workData,
        dateIni: '',
        dateEnd: '',
        name: '',
        hours: '',
        notes: ''
      })
      setOpen(false);
    } else {
      dispatch(setModalText("Erro ao introduzir registo"));
      dispatch(showModal(true));
    }

  };

  const handleWorkEdit = async () => {

    if (workData.dateIni === '') {
      setErrorMessage("Tem de selecionar a data de início");
      return;
    }
    if (workData.dateEnd === '') {
      setErrorMessage("Tem de selecionar a data de Fim");
      return;
    }
    if (workData.name === '') {
      setErrorMessage("Tem de preencher o nome da obra");
      return;
    }
    if (workData.hours === '' || Number(workData.hours) <= 0) {
      setErrorMessage("Tem de introduzir as horas estimadas");
      return;
    }

    setErrorMessage(null);
    dispatch(setLoading(true));
    const insRes = await updateWork(workID, workData.dateIni, workData.dateEnd, workData.name, workData.hours, workData.notes);
    dispatch(setLoading(false));
    if (insRes) {
      loadWorks();
      dispatch(setModalText("Registo editado com sucesso"));
      dispatch(showModal(true));
      setWorkData({
        ...workData,
        dateIni: '',
        dateEnd: '',
        name: '',
        hours: '',
        notes: ''
      })
      setOpen(false);
      setEditMode(false);
    } else {
      dispatch(setModalText("Erro ao editar registo"));
      dispatch(showModal(true));
    }

  };

  const loadWorks = async () => {
    setDatatableData(datatableData => []);
    dispatch(setLoading(true));
    const avaliableWorksRes = await getWorks('');
    if (avaliableWorksRes !== false) {

      for (let index = 0; index < avaliableWorksRes.length; index++) {
        const record = avaliableWorksRes[index];
        const itemToAdd = {
          id: record['id'],
          name: record['name'],
          init_date: new Date(record['init_date']),
          end_date: new Date(record['end_date']),
          estimated_hours: record['estimated_hours'],
          is_closed: (record['is_closed'] ? 'Sim' : 'Não')
        }
        setDatatableData(datatableData => [...datatableData, itemToAdd]);
      }
    }
    dispatch(setLoading(false));
    setDashLoading(false);
  }

  useEffect(() => {
    (async () => {
      loadWorks();
    })()
  }, []);

  // const submitForm = async () => {

  //   if (work === '') {
  //     setErrorMessage("Tem de selecionar uma obra");
  //     return;
  //   }
  //   if (workData.hours === '' || Number(workData.hours) <= 0) {
  //     setErrorMessage("Tem de introduzir as horas gastas");
  //     return;
  //   }
  //   setErrorMessage(null);

  //   dispatch(setLoading(true));
  //   const insRes = await createWorkReport(work, new Date(), workData.hours, workData.materials, workData.notes);
  //   dispatch(setLoading(false));
  //   if (insRes) {
  //     dispatch(setModalText("Registo introduzido com sucesso"));
  //     dispatch(showModal(true));
  //     setWork('');
  //     setWorkData({
  //       ...workData,
  //       hours: '',
  //       materials: '',
  //       notes: '',
  //     })

  //   } else {
  //     dispatch(setModalText("Erro ao introduzir registo"));
  //     dispatch(showModal(true));
  //   }
  // };

  return (
    <Container maxWidth="lg"
      style={{
        backgroundColor: '#EBE8E8',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: '#D2D2D2',
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        width: 1210,
        maxWidth: '100%',
        height: 'auto'
      }}>

      {dashLoading &&
        <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10
        }}>
          <CircularProgress />
        </div>
      }

      <Typography variant="h4" style={{ marginTop: 10, textAlign: 'center' }}>
        Obras
      </Typography>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Button style={{ width: 200, marginBottom: 10 }} onClick={handleClickOpen} variant="outlined">Criar nova obra</Button>

      <div style={{ height: 'auto', width: '100%', whiteSpace: "pre-line" }}>
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
          sx={{
            '--DataGrid-overlayHeight': '100px',
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            }
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          style={{ marginBottom: 20 }}
          columns={columnTitles}
          rows={datatableData}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick={true}
          // isRowSelectable={false}
          rowSelection={false}
        />
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
        {!editMode &&
          <DialogTitle>Nova Obra</DialogTitle>
        }
        {editMode &&
          <DialogTitle>Editar Obra</DialogTitle>
        }
        <DialogContent>
          {errorMessage !== null &&
            <Alert severity="error" style={{ textAlign: 'left', marginBottom: 20 }}>
              <AlertTitle>Erro</AlertTitle>
              {errorMessage}
            </Alert>
          }
          <FormControl fullWidth style={{ marginBottom: 10 }}>

            <div style={{
              paddingTop: 10
            }}></div>

            <DatePicker label="Date de início"
              defaultValue={dayjs(workData.dateIni)}
              value={dayjs(workData.dateIni)}
              format="DD/MM/YYYY"
              onChange={(newValue) => {
                setWorkData({
                  ...workData,
                  dateIni: newValue,
                })
              }}
            />
            <div style={{
              paddingTop: 10
            }}></div>

            <DatePicker label="Data de fim"
              style={{

              }}
              defaultValue={dayjs(workData.dateEnd)}
              value={dayjs(workData.dateEnd)}
              format="DD/MM/YYYY"
              onChange={(newValue) => {
                setWorkData({
                  ...workData,
                  dateEnd: newValue,
                })
              }}
            />
            <div style={{
              paddingTop: 10
            }}></div>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nome da obra"
              type="text"
              fullWidth
              variant="standard"
              style={{
                paddingTop: 10
              }}
              value={workData.name}
              onChange={(e) => {
                setWorkData({
                  ...workData,
                  name: e.target.value,
                })
              }}
              required
            />

            <TextField
              autoFocus
              margin="dense"
              id="hours"
              label="Horas estimadas"
              type="number"
              fullWidth
              variant="standard"
              style={{
                paddingTop: 10
              }}
              value={workData.hours}
              onChange={(e) => {
                setWorkData({
                  ...workData,
                  hours: e.target.value,
                })
              }}
              required
            />

            <TextField
              label="Informação adicional"
              multiline
              maxRows={5}
              variant="filled"
              style={{ marginTop: 10 }}
              value={workData.notes}
              onChange={(e) => {
                setWorkData({
                  ...workData,
                  notes: e.target.value,
                })
              }}
            />

          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          {!editMode &&
            <Button onClick={handleWorkCreate}>Criar</Button>
          }
          {editMode &&
            <Button onClick={handleWorkEdit}>Gravar</Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog
        open={closeOpen}
        onClose={() => setCloseOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Terminar Obra
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem a certeza que pretende fechar a obra?
            Ao confirmar não será possivel editar a obra nem inserir mais registos de horas na mesma.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCloseOpen(false)}>Cancelar</Button>
          <Button onClick={handleWorkClose} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
}

export default Works;
