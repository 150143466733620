import React, { useState, useEffect } from 'react';
import { Button, Container, Divider, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { setLoading, showModal, setModalText } from '../../reducer/global'
import { getWorks, createWorkReport } from '../../helpers/rest'

function NewReport() {

  const [work, setWork] = useState('');
  const dispatch = useDispatch()
  const [avaliableWorks, setAvaliableWorks] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [workData, setWorkData] = useState({
    hours: '',
    materials: '',
    notes: '',
  });
  const [date, setDate] = useState('');

  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero if needed
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  useEffect(() => {
    dispatch(setLoading(true));
    (async () => {
      setDate(formattedDate);
      const avaliableWorksRes = await getWorks();
      if (avaliableWorksRes !== false) {
        setAvaliableWorks(avaliableWorksRes);
      }
      dispatch(setLoading(false));
    })()
  }, []);

  const submitForm = async () => {

    if (work === '') {
      setErrorMessage("Tem de selecionar uma obra");
      return;
    }
    if (workData.hours === '' || Number(workData.hours) <= 0) {
      setErrorMessage("Tem de introduzir as horas gastas");
      return;
    }
    setErrorMessage(null);

    dispatch(setLoading(true));
    const insRes = await createWorkReport(work, new Date(), workData.hours, workData.materials, workData.notes);
    dispatch(setLoading(false));
    if (insRes) {
      dispatch(setModalText("Registo introduzido com sucesso"));
      dispatch(showModal(true));
      setWork('');
      setWorkData({
        ...workData,
        hours: '',
        materials: '',
        notes: '',
      })

    } else {
      dispatch(setModalText("Erro ao introduzir registo"));
      dispatch(showModal(true));
    }



  };

  return (
    <Container maxWidth="sm"
      style={{
        backgroundColor: '#EBE8E8',
        marginTop: 20,
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#D2D2D2',
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>

      <Typography variant="h4" style={{ marginTop: 10, textAlign: 'center' }}>
        Reportar horas
      </Typography>

      <Divider style={{ marginTop: 10, marginBottom: 20 }} />

      {errorMessage !== null &&
        <Alert severity="error" style={{ textAlign: 'left', marginBottom: 20 }}>
          <AlertTitle>Erro</AlertTitle>
          {errorMessage}
        </Alert>
      }

      <TextField
        type="text"
        className='TextFieldItem'
        label="Data"
        variant="filled"
        // style={{ marginTop: 10 }}
        value={date}
        readOnly
      />

      <FormControl fullWidth style={{ marginBottom: 10 }}>
        <InputLabel id="obra-select-label">Obra</InputLabel>
        <Select
          labelId="obra-select-label"
          id="obra-select"
          value={work}
          label="Obra"
          onChange={(e) => setWork(e.target.value)}
        >
          {avaliableWorks.map((item, index) => (
            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
          ))}

        </Select>
      </FormControl>

      {/* <DatePicker label="Data"
        readOnly
        style={{}}
        defaultValue={dayjs(date)}
        value={dayjs(date)}
      /> */}


      <TextField
        type="number"
        className='TextFieldItem'
        label="Horas gastas"
        variant="filled"
        style={{ marginTop: 0 }}
        value={workData.hours}
        onChange={(e) => {
          setWorkData({
            ...workData,
            hours: e.target.value,
          })
        }}
      />

      <TextField
        label="Materiais"
        multiline
        maxRows={5}
        variant="filled"
        style={{ marginTop: -5 }}
        value={workData.materials}
        onChange={(e) => {
          setWorkData({
            ...workData,
            materials: e.target.value,
          })
        }}
      />

      <TextField
        label="Notas"
        multiline
        maxRows={5}
        variant="filled"
        style={{ marginTop: 10 }}
        value={workData.notes}
        onChange={(e) => {
          setWorkData({
            ...workData,
            notes: e.target.value,
          })
        }}
      />

      <Button
        style={{
          alignSelf: 'center',
          marginTop: 10,
          marginBottom: 20
        }}
        onClick={submitForm}
        variant="outlined">Submeter</Button>

    </Container>
  );
}

export default NewReport;
