import React, { useState } from 'react';

function Home() {

  return (
    <div>
      <h1>TESTE GR HOME</h1>
    </div>
  );
}

export default Home;
